import topcard from './csiimages/top-card.png'
import './App.css';
import bottomcard from './csiimages/bottom-card.png'
import cardsmall from './csiimages/Card-small.png'


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h5>CSI Carpet Services Incorporated for all of your carpet needs. CSI has been providing excellent service since 1979 for Residential and Commercial Properties</h5>
        <h8>Our site is currently under construction to modernize the way we do business as a flooring distributor and flooring installer</h8>
      
      </header>
      <main>

        <div className={"card cards-big"}>
          <img src={topcard} className="" alt="csi-carpet-card" />
          <img src={bottomcard} className="" alt="csi-carpet-card" />
        </div>
        <div className={"card cards-small"}>
          <img src={cardsmall} className="" alt="csi-carpet-card" />
        </div>
      </main>
    </div>
  );
}

export default App;
